<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="950px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("COUPONS.coupon") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.webshop"
                v-model="formModel.status"
                :items="statuses.webshop.coupon"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("STATUSES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("STATUSES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-select
                v-if="types.webshop"
                v-model="formModel.type"
                :items="types.webshop.coupon"
                :label="$t('FORM_INPUT_NAMES.type')"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("COUPON_TYPES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("COUPON_TYPES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
              <v-text-field
                :rules="nameRules"
                v-model="formModel.id"
                label="Kupon kód"
                :id="dynamicID"
                :error-messages="messages['id']"
                @keyup="messages['id'] = ''"
                :disabled="isLoadedModel"
              ></v-text-field>

              <v-text-field
                :rules="requiredNumberRules"
                v-model="formModel.value"
                :label="$t('COUPONS.value')"
                :id="dynamicID"
                :error-messages="messages['value']"
                @keyup="messages['value'] = ''"
              >
                <span v-if="formModel.type == 2" slot="append">%</span>
                <span v-else slot="append">Ft</span>
              </v-text-field>
              <v-text-field
                v-model="formModel.email"
                :label="$t('FORMS.userEmail')"
                :id="dynamicID"
                :error-messages="messages['email']"
                @keyup="messages['email'] = ''"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="formModel.max_use_num_per_user"
                :label="$t('COUPONS.max_use_num_per_user')"
              ></v-text-field>
              <v-text-field
                v-model="formModel.max_use_num"
                :label="$t('COUPONS.max_use_num')"
              ></v-text-field>
              <CustomFieldComponent
                customFieldName="validity_date"
                :value="formModel.validity_date || []"
                :config="{
                  type: 'date_range_picker',
                }"
                @handleChangeCustomFieldValue="
                  handleChangeFormModelCustomFieldValue
                "
              >
              </CustomFieldComponent>

              <div
                v-if="required_custom_fields.validity_date"
                class="v-text-field__details"
                style="margin-top: -10px !important"
              >
                <div class="v-messages theme--light error--text" role="alert">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message">Kötelező mező</div>
                  </div>
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <v-checkbox
                v-model="formModel.generated"
                :label="$t('FORM_INPUT_NAMES.generated')"
                disabled
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <v-checkbox
                v-model="formModel.custom_fields.sale"
                :label="$t('FORM_INPUT_NAMES.sale')"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="formModel.custom_fields.cart_limit"
                :label="$t('FORM_INPUT_NAMES.cart_limit')"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row v-if="formModel.orders && formModel.orders.length > 0">
            <v-col cols="12" class="mt-3" style="font-size: 1.25rem">
              <p>Felhasznált darabszám: {{ formModel.orders.length }} db</p>
              <p>
                Kupon által generált összforgalom:
                {{ getOrdersSum(formModel.orders) }} Ft
              </p>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="formModel.orders"
                :sort-by="['id']"
                sort-desc="true"
                @click:row="handleClickItem"
              >
                <template v-slot:item.amount="{ item }">
                  {{ item.custom_fields.amount }} Ft
                </template>
                <template v-slot:item.date="{ item }">
                  <span>
                    {{
                      new Date(item.created_at).toLocaleString("hu-HU", {
                        timeZone: "UTC",
                      })
                    }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { mapGetters } from "vuex";
import { PERMISSION_TO } from "./Coupons";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";

const TRANSLATED_ATRIBUTES = [];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [];

export const INITIAL_CUSTOM_FIELDS = {
  sale: null,
  cart_limit: 0,
};

const INITIAL_REQUIRED_CUSTOM_FIELDS = {
  validity_date: false,
};

export const initialFormData = () => ({
  id: null,
  status: 1,
  // translations: {},
  type: 1,
  max_use_num: -1,
  max_use_num_per_user: 1,

  value: 0,
  validity_date: [],
  validity_start: null,
  validity_end: null,
  isNew: true,
  custom_fields: INITIAL_CUSTOM_FIELDS,
});

export default {
  name: "PageForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  components: { CustomFieldComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      isLoadedModel: false,
      permissionTo: PERMISSION_TO,

      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,
      required_custom_fields: INITIAL_REQUIRED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      requiredNumberRules: [
        (v) =>
          // (!!v && typeof +v == "number") || this.$t("ERRORS.field_is_required"),
          !!v || typeof +v == "number" || this.$t("ERRORS.field_is_required"),
      ],
      // editorConfig: {
      //   htmlEncodeOutput: false,
      //   entities: false,
      //   extraPlugins: "justify,font,copyformatting",
      //   filebrowserBrowseUrl:
      //     window.location.origin +
      //     "/" +
      //     this.$router.resolve({
      //       name: "media_library",
      //       query: { component_in_window: 1 },
      //     }).href +
      //     "?type=Files",
      //   filebrowserImageBrowseUrl:
      //     window.location.origin +
      //     "/" +
      //     this.$router.resolve({
      //       name: "media_library",
      //       query: { component_in_window: 1 },
      //     }).href +
      //     "?type=Images",
      // },
      headers: [
        {
          text: this.$t("TABLE.date"),
          value: "date",
        },
        { text: "Azonosító", value: "order_number" },
        {
          text: this.$t("TABLE.amount"),
          value: "amount",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    types() {
      return this.$store.getters.types;
    },

    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.isNew
        ? this.$t("FORMS.edit") + this.$t("COUPONS.coupon")
        : this.$t("MENU.NEW") + " " + this.$t("COUPONS.coupon");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.required_custom_fields = Object.assign(
          {},
          INITIAL_REQUIRED_CUSTOM_FIELDS
        );
        this.isLoadedModel = false;
        this.selectedLocale = i18nService.userLanguages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.isLoadedModel = true;
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              data.value = +data.value;

              if (!data.custom_fields) {
                data.custom_fields = INITIAL_CUSTOM_FIELDS;
              }
              data.validity_date = [];
              if (data.validity_start) {
                data.validity_date.push(data.validity_start);
              }
              if (data.validity_end) {
                data.validity_date.push(data.validity_end);
              }

              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          // this.formModel.custom_fields = this.initCustomFields();
          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    handleSaveModalForm() {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      if (!this.formModel.validity_start || !this.formModel.validity_end) {
        this.required_custom_fields["validity_date"] = true;
      } else {
        this.required_custom_fields["validity_date"] = false;
      }
      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;

        if (!model.isNew) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },
    handleChangeFormModelCustomFieldValue(property, val) {
      if (val.length > 0) {
        // this.formModel.validity_start = val[0];
        this.$set(this.formModel, "validity_start", val[0]);
      } else {
        // this.formModel.validity_start = null;
        this.$set(this.formModel, "validity_start", null);
      }
      if (val.length > 1) {
        // this.formModel.validity_end = val[1];
        this.$set(this.formModel, "validity_end", val[1]);
      } else {
        // this.formModel.validity_end = null;
        this.$set(this.formModel, "validity_end", null);
      }
    },

    getOrdersSum(orders) {
      return orders.reduce(
        (sum, order) => sum + 1 * order.custom_fields.amount,
        0
      );
    },

    handleClickItem(item) {
      const routeData = this.$router.resolve({
        path: `/webShop/orders/` + item.id,
      });
      window.open(routeData.href, "_blank");
    },
  },

  mounted() {
    this.setTranslatedAttributes();
  },
};
</script>
